import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import { getShortMonth } from "./training-actions"

const TrainingList = ({ max }) => {
  const data = useStaticQuery(graphql`
    query {
      events: allMarkdownRemark(
        filter: {
          frontmatter: { active: { eq: true }, type: { eq: "training" } }
        }
        sort: { fields: frontmatter___date, order: ASC }
      ) {
        edges {
          node {
            fields {
              id
              slug
            }
            frontmatter {
              title
              type
              host
              date
              startTime
              endTime
              address
              regPDF
              flyerPDF
            }
            html
            excerpt
          }
        }
      }
    }
  `)

  let showMax = max || data.events.edges.length

  return (
    <ul className="events">
      {data.events.edges.map((edge, i) => {
        const event = edge.node
        if (showMax > 0) {
          showMax--
          return (
            <li key={`event-${i}`} className="event clearfix">
              <div
                className="event-stamp"
                data-gotohref="/training/event/<%= event.eventId %>"
              >
                <div className="short-month">
                  {getShortMonth(event.frontmatter.date)}
                </div>
                <div className="date-num">
                  {event.frontmatter.date.split("-")[2]}
                </div>
                <div className="status">Open</div>
              </div>
              <div className="event-info">
                <a href={`${event.fields.slug}`} className="event-name">
                  {event.frontmatter.title}
                </a>
                <p className="event-host">{event.frontmatter.host}</p>
                <p className="event-date">{event.frontmatter.date}</p>
                <p className="event-address">{event.frontmatter.address}</p>
              </div>
              <div className="event-description">
                <p className="label">Description</p>
                <div dangerouslySetInnerHTML={{ __html: event.excerpt }} />
              </div>
            </li>
          )
        }
      })}
    </ul>
  )
}

export default TrainingList
