import { graphql, useStaticQuery } from "gatsby"
import React from "react"

const ManufacturerList = () => {
  const data = useStaticQuery(graphql`
    query {
      manufacturers: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "//manufacturers//" }
          frontmatter: { active: { eq: true } }
        }
        sort: { fields: frontmatter___priority, order: ASC }
      ) {
        edges {
          node {
            fields {
              slug
              id
            }
            frontmatter {
              type
              title
              link
              active
            }
          }
        }
      }
    }
  `)

  return (
    <ul className="inline-list manufacturers">
      {data.manufacturers.edges
        .filter(a => a.node.frontmatter.active)
        .map((edge, i) => {
          const manufacturer = edge.node
          return (
            <li className={`manufacturer`} key={`manufacturer-${i}`}>
              <div className={`icon manufacturer ${manufacturer.fields.id}`} />
              <p className="label">{manufacturer.frontmatter.title}</p>
              <ul className="links-list">
                <li className="clearfix">
                  <span className="icon link web black" />
                  <a href={manufacturer.frontmatter.link}>Website</a>
                </li>
              </ul>
            </li>
          )
        })}
    </ul>
  )
}

export default ManufacturerList
