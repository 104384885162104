import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import TrainingList from "../training/training-list"
import EventList from "../events/events-list"
import ProductList from "../products/product-list"
import ManufacturerList from "../manufacturers/manufacturer-list"

const IndexPage = () => (
  <Layout headerStyle="index">
    <SEO title="Home" />
    <h1 className="hide">Hydronic Systems Canada Incorporated</h1>
    <section id="homepage-products" className="">
      <h2>Products</h2>
      <ProductList/>
      {/*<g:include view="/products/productList.gsp" model="[catalogs:catalogs]"/>*/}
    </section>
    <section className="homepage-manufacturers">
      <h2>Trusted Manufacturers</h2>
      <ManufacturerList/>
      {/*<g:include view="/manufacturer/manufactList.gsp" model="[manufactList:manufacturers,showInfo:false]"/>*/}
    </section>
    {/* <section className="homepage-events">

      <h2>Training</h2>
      <TrainingList max={3}/>
      <a className="right" href="/training/">See All Training</a>
    </section> */}
    <section className="homepage-events">

      <h2>Events</h2>
      <EventList max={3}/>
      {/*<g:include view="/event/eventList.gsp" model="[eventList:events,max:3]"/>*/}
      {/*<a className="right" href="/events/">See All Events</a>*/}
      <br/>
    </section>
  </Layout>
)


  //    {/*<g:include view="/event/eventList.gsp" model="[eventList:events,max:3]"/>*/}

// export const indexQuery = graphql`
//     query{
//
//         downArrow: file(relativePath: { eq: "down-arrow.png" }) {
//             childImageSharp {
//                 fluid(quality:100) {
//                     ...GatsbyImageSharpFluid_noBase64
//                 }
//             }
//         }
//
//     }
// `

export default IndexPage
